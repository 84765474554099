<template>
  <v-container fill-height fluid class="justify-center">
    
    <v-snackbar v-model="snackErreur" color="red">
      {{snackMessage}}
      <v-btn text color="primary" @click.native="snackErreur = false">Close</v-btn>
    </v-snackbar>

    <!--<v-dialog v-model="dialog" :overlay="false" max-width="500px" transition="dialog-transition">-->
    
      <v-card class="pa-3" style="max-width: 450px" center>
        <v-card-title primary-title>
          Login
        </v-card-title>

        <v-card-text>
          <v-form ref="loginForm" v-model="valid" lazy-validation>
                <v-text-field name="email" v-model="email" :rules="loginEmailRules" label="Adresse de courriel" required></v-text-field>

                <v-text-field name="password" v-model="password" label="Mot de passe"
                  :rules="[rules.required, rules.min]" required
                  :append-icon="show1?'eye':'eye-off'" :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1">
                </v-text-field>


          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="!valid" color="success" @click="validate"> Login </v-btn>
          <v-spacer></v-spacer>
          <router-link to="/request-password-reset">Mot de passe oublié</router-link>
          
        </v-card-actions>

      </v-card>

    <!--</v-dialog>-->
    
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  components: { },

  data() {
    return {
      //dialog: true,
      valid: true,
      email: "",
      password: "",
      loginEmailRules: [
        v => !!v || "Required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      emailRules: [
        v => !!v || "Required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      show1: false,
      rules: {
        required: value => !!value || "Required.",
        min: v => (v && v.length >= 6) || "Min 6 characters"
      },
      snackErreur: false,
      snackMessage: "",
      loading: false

    }
  },

  methods: {
    validate() {
      if (this.valid) {
        this.loading = true;
        const body = {email: this.email, password: this.password}
        axios.post("/api/wdUsers/connexion", body)
          .then(function (response) {
            this.loading = false;
            this.$user.id = response.data.id;
            this.$user.ttl = response.data.ttl;
            this.$user.created = response.data.created;
            this.$user.userId = response.data.userId;
            this.$user.upId = response.data.upId;
            this.$user.userParam = response.data.userParam;
            this.$user.roles = response.data.roles;
            this.$user.user = response.data.user;
            this.$updateRoles(this.$user.roles);
            localStorage.setItem('user', JSON.stringify(this.$user));
            if (this.$route.query.to != undefined) {
              this.$router.push(this.$route.query.to);
            }
            else {
              this.$router.push('/');
            }
          }.bind(this))
          .catch(error => {
            this.snackMessage = error.message + " : " + error.response.statusText;
            this.snackErreur = true
            this.loading = false;
          })
      }
    }
  }

}
</script>

<style>

</style>